import React from "react"
import { FaFacebookF, FaInstagram, FaDribbble } from 'react-icons/fa'
import { navigate } from "gatsby"
// const Contact = () => (
//     <div className="sub-container">
//         <section className="contact section bd-container" id="contact">
//             <div className="contact__container bd-grid">
//                 <div className="contact__data" data-sal="slide-down" data-sal-delay="200" data-sal-duration="800" data-sal-easing="ease">
//                     <h2 className="section-title contact__initial">Let's Talk</h2>
//                     <p className="contact__description">Any question or discussion, feel free to contact me.</p>
//                     <a href="mailto:info@andigoh.com"><h1 className="home__subtitle">info@andigoh.com</h1></a>
//                     <div className="contact__social_media">
//                         <a href="https://www.facebook.com/andigohzg" className="footer__social" target="_blank" rel="noopener noreferrer"><FaFacebookF className="bx bxl-facebook" /></a>
//                         <a href="https://www.instagram.com/andigohh/" className="footer__social" target="_blank" rel="noopener noreferrer"><FaInstagram className="bx bxl-instagram" /></a>
//                         <a href="https://www.twitter.com/andigohh/" className="footer__social" target="_blank" rel="noopener noreferrer"><FaTwitter className="bx bxl-twitter" /></a>
//                     </div>
//                 </div>

//                 <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" className="contact__form" data-sal="slide-down" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease">
//                     <input type="hidden" name="form-name" value="contact" />
//                     <h2 className="section-title contact__initial">Let Me Know Here</h2>
//                     <input name="name" type="text" placeholder="Enter your full name" className="contact__input" />
//                     <input name="email" type="email" placeholder="Enter your email" className="contact__input" />
//                     <textarea name="message" id="" cols="0" rows="10" placeholder="Write message" className="contact__input"></textarea>
//                     <button type="submit" className="contact__button button">Send</button>
//                 </form>
//             </div>
//         </section>
//     </div>
// )

// export default Contact

function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
}

export default class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {},
            errors: {}
        };
    }
    // state = {
    //     name: '',
    //     email: '',
    //     message: ''
    // };

    // handleInputChange = e => {
    //     this.setState({
    //         [e.target.name]: e.target.value
    //     });
    // };

    handleValidation() {
        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "Name is required";
        }

        if (typeof fields["name"] !== "undefined") {
            if (!fields["name"].match(/^[a-zA-Z\s]+$/)) {
                formIsValid = false;
                errors["name"] = "Please fill with letters only";
            }
        }

        //Email
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "Email is required";
        }

        if (typeof fields["email"] !== "undefined") {
            let lastAtPos = fields["email"].lastIndexOf('@');
            let lastDotPos = fields["email"].lastIndexOf('.');

            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') == -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
                formIsValid = false;
                errors["email"] = "Email is not valid";
            }
        }

        if (!fields["message"]) {
            formIsValid = false;
            errors["message"] = "Message is required";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }


    // encode = (data) => {
    //     return Object.keys(data)
    //         .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    //         .join("&");
    // }

    handleSubmit = async (e) => {
        e.preventDefault();

        console.log(this.handleValidation())
        if (this.handleValidation()) {
            const form = e.target;
            fetch("/", {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: encode({
                    "form-name": form.getAttribute("name"),
                    ...this.state.fields
                    
                })
            })
                .then(() => navigate(form.getAttribute("action")))
                .catch(error => alert(error));
        } else {
            alert("Form has errors.");
        }
    };

    handleInputChange(field, e) {
        let fields = this.state.fields;
        fields[field] = e.target.value;
        this.setState({ fields });
    }

    render() {

        return (
            <div className="sub-container">
                <section className="contact section bd-container" id="contact">
                    <div className="contact__container bd-grid">
                        <div className="contact__data" data-sal="slide-down" data-sal-delay="200" data-sal-duration="800" data-sal-easing="ease">
                            <h2 className="section-title contact__initial">Let's Talk</h2>
                            <p className="contact__description">Any question or discussion, feel free to contact me.</p>
                            <a href="mailto:info@andigoh.com"><h1 className="home__subtitle">info@andigoh.com</h1></a>
                            <div className="contact__social_media">
                                <a href="https://www.facebook.com/andigohzg" className="footer__social" target="_blank" rel="noopener noreferrer"><FaFacebookF className="bx bxl-facebook" /></a>
                                <a href="https://www.instagram.com/andigohh/" className="footer__social" target="_blank" rel="noopener noreferrer"><FaInstagram className="bx bxl-instagram" /></a>
                                <a href="https://dribbble.com/andigoh" className="footer__social" target="_blank" rel="noopener noreferrer"><FaDribbble className="bx bxl-twitter" /></a>
                            </div>
                        </div>

                        <form name="contact" id="contact-form" method="post" data-netlify="true" data-netlify-honeypot="bot-field" action="/thanks/" onSubmit={this.handleSubmit} className="contact__form" data-sal="slide-down" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease">
                            <input type="hidden" name="form-name" value="contact" />
                            <p hidden>
                                <label>
                                    Don’t fill this out:{" "}
                                    <input name="bot-field" onChange={this.handleChange} />
                                </label>
                            </p>
                            <h2 className="section-title contact__initial">Let Me Know Here</h2>
                            <div className="contact__subcontainer">
                                <input name="name" id="contact-form-name" type="text" onChange={this.handleInputChange.bind(this, "name")} value={this.state.fields["name"]} placeholder="Enter your full name" className="contact__input" />
                                <span className="contact__error">{this.state.errors["name"]}</span>
                            </div>
                            <div className="contact__subcontainer">
                                <input name="email" id="contact-form-email" type="email" onChange={this.handleInputChange.bind(this, "email")} value={this.state.fields["email"]} placeholder="Enter your email" className="contact__input" />
                                <span className="contact__error">{this.state.errors["email"]}</span>
                            </div>
                            <div className="contact__subcontainer">
                                <textarea name="message" id="contact-form-message" onChange={this.handleInputChange.bind(this, "message")} value={this.state.fields["message"]} cols="0" rows="10" placeholder="Write message" className="contact__input"></textarea>
                                <span className="contact__error">{this.state.errors["message"]}</span>
                            </div>

                            <button type="submit" className="contact__button button">Send</button>
                        </form>
                    </div>
                </section>
            </div>
        )

    }

}
