import React from "react"

const Footer = () => (

    <div>
        <footer className="footer">
            <p className="footer__copy">&#169; {new Date().getFullYear()}
            {` `}
            <a href="https://www.andigoh.com" className="footer__copy">Andigoh</a>. All right reserved</p>
        </footer>
    </div>
)

export default Footer